import config from '@/config';

window.onload = function () {
    if (config.analytics_key) {
        window.dataLayer = window.dataLayer || [];

        function gtag() {
            dataLayer.push(arguments);
        }

        gtag('js', new Date());
        gtag('config', config.analytics_key, {'anonymize_ip': true});
    }
};