import Glide from '@glidejs/glide';

if (document.querySelector('.glide')) {
    new Glide('.glide').mount();
}

if (document.querySelector('.c-sponsors-slider')) {
    new Glide('.sponsor-slider', {
        startAt: 1,
        perView: 7,
        bound: true,
        peek: { before: 100, after: 65 },
        breakpoints: {
            991.98: {
             perView: 5,
            },
            768: {
                perView: 3,
            },
            576: {
                perView: 2,
            }
        },
    }).mount();
}

