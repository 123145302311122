import config from '@/config';
import '../scss/main.scss';

// ANIMATION LIBRARY
// https://www.npmjs.com/package/gsap
import TweenLite from 'gsap/TweenLite';
import navigation from './components/nav.js';
import $ from "jquery";
import sliders from './components/sliders.js';
import analytics from './components/analytics.js';

// INIT NAVIGATION
new navigation();

console.log("pieter");

// IF USING SLICK ENABLE THIS LINE.
// require('script-loader!../../node_modules/slick-carousel/slick/slick');

// SET JQUERY
window.$ = jQuery;

// Smooth scroll
$(document).ready(function () {
    (function($) {

        var allPanels = $('.accordion > .accordion-item > .item-body').hide();

        $('.accordion > .accordion-item > .item-header').click(function(e) {
            $(this).parent().find('.item-body').stop();
            // Keep this here if customer wants panels to be closed.
            // $(this).parent().siblings().find('.item-body').slideUp(() => $(this).parent().find('.item-header').removeClass('open'));
            $(this).toggleClass('open');
            $(this).parent().find('.item-body').slideToggle();
            return false;
        });

    })(jQuery);
});
