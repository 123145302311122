export default class Navigation {
    constructor() {
        // Set elements
        this.el = {
            overlay: document.querySelector('.nav-overlay'),
            nav: document.querySelector('nav'),
        };

        // Add listeners
        this.addListeners();

    }

    addListeners() {
        let _this = this;
        console.log("piet");
        document.querySelector('.nav-toggle').addEventListener('click', () => {
            console.log("kees");
            this.toggle(true);
        });

        let navLinks = document.querySelectorAll('nav a');

        [].forEach.call(navLinks, function (link) {
            link.addEventListener('click', function () {
                // Toggle menu
                if (document.querySelector('nav').classList.contains('open')) {
                    _this.toggle(false);
                }
                // Toggle classes
                link.classList.remove('current_page_item');
                this.classList.add('current_page_item');
            });
        });
    }

    toggle() {
        let anim = TweenLite.to(this.el.overlay.querySelector('span'), 0.4, {
            z: 0,
        });
        if (this.el.nav.classList.contains('open')) {
            document.querySelector('html').style = '';
            document.querySelector('header').classList.remove('menu-open');
            this.el.nav.classList.remove('open');
            anim.reverse();
            return;
        }
        document.querySelector('html').style = 'overflow: hidden';
            document.querySelector('header').classList.add('menu-open');
        this.el.nav.classList.add('open');

    }
}
